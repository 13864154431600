import { render, staticRenderFns } from "./ArbeitsgaengeImport.vue?vue&type=template&id=f2b716a4&"
import script from "./ArbeitsgaengeImport.vue?vue&type=script&lang=js&"
export * from "./ArbeitsgaengeImport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports