<template>
  <div>
    <h2 class="mb-4">Arbeitsgänge Import</h2>

    <div class="mb-4">
      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="insertData"
      >
        Arbeitsgänge importieren
      </button>

      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="updateData"
      >
        Update Arbeitsgänge Tabelle
      </button>
    </div>

    <div v-if="$apollo.queries.artikel_arbeitsga_nge.loading">
      Lade Daten...
    </div>

    <dashboard-element>
      <dashboard-head title="Arbeitsgaenge" link="/arbeitsgaenge" />
      <dashboard-table class="p-3">
        <dashboard-card
          v-for="arbeitsgang in arbeitsgaenge"
          :key="arbeitsgang.artikelcode"
          :title="`${arbeitsgang.artikelcode}`"
          :content="`${arbeitsgang.arbeitsgang}`"
          :link="`/artikel/${arbeitsgang.artikelcode}`"
          :date="arbeitsgang.created_at"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "@/components/dashboard/DashboardElement.vue";
import DashboardHead from "@/components/dashboard/DashboardHead.vue";
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import DashboardTable from "@/components/dashboard/DashboardTable.vue";
import { supabase } from "../../plugins/supabase";

const hello = 4 + 2;
console.log(hello);

export const GET_MY_DATA = gql`
  query artikel_arbeitsga_nge {
    artikel_arbeitsga_nge(where: { Arbeitsgang: { _nlike: "" } }) {
      Arbeitsgang
      Artikelcode
    }
  }
`;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  apollo: {
    artikel_arbeitsga_nge: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  },
  computed: {
    arbeitsgaenge() {
      return this.$store.getters.getAllArbeitsgaenge;
    }
  },
  methods: {
    reduceArray: () => {
      const arr = [
        { name: "qewregf dqewafs", value: "qewregf dqewafs answer", count: 2 },
        {
          name: "survey with select",
          value: "survey with select answer",
          count: 2
        },
        { name: "werasd", value: "Donald", count: 1 },
        { name: "werasd", value: "Jim", count: 1 }
      ];

      const result = arr.reduce((acc, d) => {
        const found = acc.find(a => a.name === d.name);
        //const value = { name: d.name, val: d.value };
        const value = { value: d.value, count: d.count }; // the element in data property
        if (!found) {
          //acc.push(...value);
          acc.push({ name: d.name, data: [value] }); // not found, so need to add data property
        } else {
          //acc.push({ name: d.name, data: [{ value: d.value }, { count: d.count }] });
          found.data.push(value); // if found, that means data property exists, so just push new element to found.data.
        }
        return acc;
      }, []);

      console.log(result);
    },
    parseNumber(str) {
      return parseFloat(str.replace(",", "."));
    },
    async updateData() {
      this.artikel_arbeitsga_nge.map(ag =>
        supabase.from("artikel").upsert([
          {
            customernumber: ag.KundenNr,
            company: ag.Firma
          }
        ])
      );
    },
    async insertData() {
      const neue_arbeitsgaenge = [];
      this.artikel_arbeitsga_nge.map(ag =>
        neue_arbeitsgaenge.push({
          artikelcode: ag.Artikelcode,
          arbeitsgang: ag.Arbeitsgang
        })
      );

      await supabase.from("arbeitsgaenge").insert(neue_arbeitsgaenge);
    }
  }
};
</script>
